.ios-notification {
    -webkit-backdrop-filter: blur(33.2px) brightness(100%);
    backdrop-filter: blur(33.2px) brightness(100%);
    background-color: #f5f5f599;
    border-radius: 18px;
    height: 111px;
    position: relative;
    width: 370px;
  }
  
  .ios-notification .title {
    color: #000000;
    font-family: "SF Pro Text-Bold", Helvetica;
    font-size: 14px;
    font-weight: 700;
    left: 40px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 9px;
    width: 254px;
  }
  
  .ios-notification .message {
    color: #000000;
    font-family: "SF Pro Text-Regular", Helvetica;
    font-size: 15px;
    font-weight: 400;
    left: 48px;
    letter-spacing: -0.2px;
    line-height: normal;
    position: absolute;
    top: 28px;
    width: 256px;
  }
  
  .ios-notification .media {
    height: 32px;
    left: 324px;
    object-fit: cover;
    position: absolute;
    top: 33px;
    width: 32px;
  }
  
  .ios-notification .time {
    color: #838383;
    font-family: "SF Pro Text-Regular", Helvetica;
    font-size: 13px;
    font-weight: 400;
    left: 330px;
    letter-spacing: -0.2px;
    line-height: normal;
    position: absolute;
    text-align: right;
    top: 12px;
  }
  
  .ios-notification .app-icon {
    height: 38px;
    left: 10px;
    object-fit: cover;
    position: absolute;
    top: 36px;
    width: 38px;
  }
  